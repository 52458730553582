import {jsPDF} from "jspdf";
import autoTable from "jspdf-autotable";
import {AlertType} from "../../utils/interfaces";

const textColor = '#000000'
const titleColor = '#1e235f'

export default function createReportDownload(alert:any, logs: any, t: any) {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({
    orientation: 'portrait',
    unit: 'px',
  })
  const filename = `${alert.date}_${alert.providerName}_${alert.providerId}.pdf`
  const x = 30
  let y = 10

  const img = new Image();
  img.src = '/logo-dark.png';
  doc.addImage(img, 'PNG', x+4, y, 80, 40)

  doc.setFontSize(13)
  doc.textWithLink('http://www.riskintelligence.ca/', x+120, y+16, {
    url: 'http://www.riskintelligence.ca/',
  })
  doc.text('Email: support@riskintelligence.ca', x+120, y+36)

  y += 64
  doc.setFontSize(20)
  doc.setFont('', '', 'bold')
  doc.text('Privacy Breach Alert Report', x, y)
  y += 24
  doc.setFont('', '', 'normal')

  doc.setFontSize(16)
  doc.setTextColor(titleColor)
  doc.text('Alert Information', x, y)
  y += 16

  doc.setTextColor(textColor)
  doc.setFontSize(12)
  // doc.text(`Risk: ${alert?.riskLevel}`, x, y)
  // y += 12
  // doc.text(`Provider ID: ${alert?.providerId}`, x, y)
  // y += 12
  // doc.text(`Provider Name: ${alert?.providerName}`, x, y)
  // y += 12
  // doc.text(`Date: ${alert?.date}`, x, y)
  // y += 12
  // doc.text(`Application: ${alert?.appName}`, x, y)
  // y += 12
  // doc.text(`Organization: ${alert?.orgName}`, x, y)
  // y += 12
  const alertData =
    `Risk: ${alert.riskLevel}
Provider ID: ${alert.providerId}
Provider Name: ${alert.providerName}
Date: ${alert.date}
Application: ${alert.appName}
Organization: ${alert.orgName}`
  autoTable(doc, {
    startY: y,
    body: [[alertData]]
  })
  // @ts-ignore
  y = doc.lastAutoTable ? doc.lastAutoTable.finalY : y

  y+= 16
  doc.setTextColor(titleColor)
  doc.setFontSize(16)
  doc.text('Risk Indicators and Logs', x, y)
  y += 20
  doc.setTextColor(textColor)
  alert.alertEventGroups.forEach((alertType: AlertType) => {
    doc.setTextColor('#5B5B5B')
    doc.setFontSize(13)
    doc.text(alertType.type, x, y)
    doc.setTextColor(textColor)
    y += 10
    const map = alertType.alertEvents.map((e) => ({
      Client: e.clientId ? `${e.clientName} (${e.clientId})` : '',
      Risk: t(`indicatorLevels.${e.level}`),
      Provider: `${alert.providerName} (${e.providerId})`,
    }))
    autoTable(doc, {
      startY: y,
      body: map,
      columns: [
        {
          header: 'Client',
          dataKey: 'Client',
        },
        {
          header: 'Risk',
          dataKey: 'Risk',
        },
        {
          header: 'Provider',
          dataKey: 'Provider',
        },
      ],
    })
    // @ts-ignore
    y = doc.lastAutoTable ? doc.lastAutoTable.finalY : y
    y += 20
  })

  y += 4
  doc.setTextColor(titleColor)
  doc.setFontSize(16)
  doc.text('Original Audit Logs', x, y)
  y += 16
  doc.setTextColor(textColor)

  autoTable(doc, {
    startY: y + 4,
    columns: [
      {
        header: 'Application',
        dataKey: 'Application',
      },
      {
        header: 'Default_x0020_Program',
        dataKey: 'Default_x0020_Program',
      },
      {
        header: 'Type',
        dataKey: 'Type',
      },
      {
        header: 'TimeStamp',
        dataKey: 'TimeStamp',
      },
      {
        header: 'FullName',
        dataKey: 'FullName',
      },
      {
        header: 'Status',
        dataKey: 'Status',
      },
      {
        header: 'TranID',
        dataKey: 'TranID',
      },
      {
        header: 'MiscID',
        dataKey: 'MiscID',
      },
    ],
    body: logs,
    tableWidth: 'auto',
  })

  // @ts-ignore
  // y = doc.autoTable.previous.finalY + 20
  // doc.text('Risk Indicators and Logs', x, y)

  // for (let i = 0; i < 100; i++) {
  //   if (y > doc.internal.pageSize.height - 20) {
  //     doc.addPage()
  //     y = 20
  //   }
  // doc.text(`Risk: ${alert?.riskLevel}`, x, y)
  //   y+=12
  // }
  doc.save(filename)
}
